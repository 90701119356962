import React from "react";
import "./Programe.css";
import { programsData } from "../../data/programsData";
import right_arrow from "../../Assets/rightArrow.png";

const Programe = () => {
  return (
    <div className="programs" id="programs">
      {/* Header for progrmaes */}

      <div className="programs-header">
        <span className="stroke-text">EXPLORE OUR</span>
        <span>PROGRAMS</span>
        <span className="stroke-text">TO SHAPE YOU</span>
      </div>

      <div className="programs-categories">
        {programsData.map((programsData) => (
          <div className="category">
            {programsData.image}
            <span>{programsData.heading}</span>
            <span>{programsData.details}</span>
            <div className="join-now">
              <span>Join Now</span>
              <img src={right_arrow} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programe;
