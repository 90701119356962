import React from "react";
import "./Reasons.css";
import image1 from "../../Assets/image1.png";
import image2 from "../../Assets/image2.png";
import image3 from "../../Assets/image3.png";
import image4 from "../../Assets/image4.png";
import nb from "../../Assets/nb.png";
import adidas from "../../Assets/adidas.png";
import nike from "../../Assets/nike.png";
import tick from "../../Assets/tick.png";

const Reasons = () => {
  return (
    <div className="reasons" id="reasons">
      {/* Left Side start */}
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      {/* Left Side end */}

      {/* Right Side Start */}
      <div className="right-r">
        <span>SOME REASONS</span>
        <div>
          <span className="stroke-text">WHY</span>
          <span>CHOOSE US?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt=""></img>
            <span>OVER 140+ EXPERT COACHS</span>
          </div>
          <div>
            <img src={tick} alt=""></img>
            <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
          </div>
          <div>
            <img src={tick} alt=""></img>
            <span>1 FREE PROGRAM FOR NEW MEMBER</span>
          </div>
          <div>
            <img src={tick} alt=""></img>
            <span>RELIABLE PARTNERS</span>
          </div>
        </div>
        <span style={{ color: "var(--gray)", fontWeight: "normal" }}>
          OUR PARTNERS
        </span>
        <div className="partners">
          <img src={nb} alt="" />
          <img src={adidas} alt="" />
          <img src={nike} alt="" />
        </div>
        {/* Deatails Side End */}
      </div>
    </div>
  );
};

export default Reasons;
